import React, { Component } from 'react';
import {  Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import FontAwesome from 'react-fa';
import Meeting from './Meeting.js';

export default class SundayServcies extends Component {

  render() {

    return (
      <div>
        <div style={{width: '90%', margin: 'auto', textAlign:'left'}}>
          <Grid >
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{color: '#fff', height: '150px',  background: 'url(images/lighthouse.jpg) center / cover'}}>Monthly Prayer Meeting : 每月祈禱會</CardTitle>
                <CardText>
                  <p>
                    &emsp;<FontAwesome name='calendar'/> : &emsp;Every 1st Sunday of the month at 4:15pm <br/>
                    &emsp;<FontAwesome name='map-marker'/> : &emsp;Portswood Church
                  </p>
                  <h6>We have a monthly prayer meeting after the Sunday service on every 1st week of the month to pray together for various ministires. </h6>
                </CardText>
              </Card>
            </Cell>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{color: '#fff', height: '150px',  background: 'url(images/path_john14_6.jpg) center / cover'}}>Evening Prayer Meeting : 晚間禱告會</CardTitle>
                <CardText>
                  <p>
                    &emsp;<FontAwesome name='calendar'/> : &emsp;周三晚上九時 Wednesday evening 9pm<br/>
                    &emsp;<FontAwesome name='map-marker'/> : &emsp;Zoom <br/>
                    &emsp;<FontAwesome name='address-card'/> : &emsp;洪文清姊妹 Wendy Hong <br/>
                    &emsp;<FontAwesome name='envelope'/> : &emsp;wenqing.hong@sccc.org.uk <br/>
                  </p>
                  <h6>每周三晚間禱告會</h6>
                </CardText>
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>

    );
  }
}
