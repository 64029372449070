import React, { Component } from 'react';
import {  Grid, CardActions, Card, CardText, CardTitle, titleStyle } from 'react-mdl';
import FontAwesome from 'react-fa';
// import Event from '../events/Event.js'

export default  class Sermons extends Component {
  
    // state = {
    //   sermons: []
    // }

    // componentDidMount() {
    //   fetch('https://sccc.org.uk/server/api/index.php/events/past8/1')
    //     .then(res => res.json())
    //     .then(events => this.setState({ sermons:events.events }));
    // }

    // renderEvents() {
    //   return this.state.sermons.map((sermon, index) => (
    //     <Cell col={6} key={index}>
    //       <Event key={index} event={sermon} titleBackground="url(/images/blue_background.jpg) center / cover"/>
    //     </Cell>
    //   ));
    // }

    render() {

      const titleStyle = {
        color: 'white',
        background: "url(/images/blue_background.jpg) center / cover"
      };

      return (
        <div>
        <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
          <CardTitle style={titleStyle}>Sermons</CardTitle>
          <CardText>
            <h6> 
              You can join our live streaming of Sunday worship and sermon or watch previous recording at <a href="https://www.youtube.com/channel/UCksToT1CijeZ-xfP4WEdKwA">"Media at SCCC on YouTube"</a>.
            </h6> 
          </CardText>
          <CardActions>
            <h5><font color="red">
            <a href="https://www.youtube.com/channel/UCksToT1CijeZ-xfP4WEdKwA"><FontAwesome name='youtube'/>&nbsp;&nbsp;&nbsp;SCCC YouTube Live Channel</a>
            </font></h5>
          </CardActions>
        </Card>
      </div>

      );
    }

  }
