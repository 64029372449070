import React, { Component } from 'react';
import {  Grid, Cell } from 'react-mdl';
import _ from 'underscore';
import Event from './Event.js'


export default class UpcomingEvents extends Component {
  state = {
    events: []
  }

  componentDidMount() {
    fetch('https://sccc.org.uk/server/api/index.php/events/upcoming')
    .then(res => res.json())
    .then(data => {
      const events = _.where(data.events, {category_id: "5"});
      return events;
    })
    .then(events => this.setState({ events:events }));
  }

  renderEvents() {
    return this.state.events.map((event, index) => (
      <Cell col={12} key={index}>
        <Event key={index} event={event} titleBackground="url(/images/green_background.jpg) center / cover"/>
      </Cell>
    ));
  }

  render() {
    return (
      <div>
        <h3>Upcoming Events</h3>
        <Grid>
          {this.renderEvents()}
        </Grid>
      </div>
    );
  }


}
