import React, { Component } from 'react';
import {  Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import FontAwesome from 'react-fa';
import Meeting from './Meeting.js';

export default class Fellowships extends Component {

  renderMeetings() {
    const meetings = [
      {
            _id: 0,
            name: "Enoch Fellowship : 以諾團契 (粵語)",
            venue: "SCCC Church Center",
            time: "周一早上十時 Every Monday 10:00am",
            url: "/images/enoch_group.jpg",
            contacts: [
              {
                name: "衞傳道 Pastor Henry, 彭華發弟兄",
                email: "henry.wai@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "The group consists mainly (but not exclusively) of local Chinese and those in the food catering business. The speaking medium is Cantonese."
              }
            ]
          },
          {
            _id: 1,
            name: "Cantonese Women Fellowship : 粵語婦女團契",
            url: "/images/we-are-one-body-in-Christ.jpg",
            venue: "SCCC Church Centre",
            time: "週四早上十時 Thursday morning 10am (僅於學期內舉行 School term time only)",
            contacts: [
              {
                name: "Irene Wai - 衞師母",
                email: "irene.wai@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "歡迎說粵語的姐妹們加入我們團契，聚會內容有破冰遊戲，唱詩歌，查經分享學習神的話語，禱告，我們還舉辦郊遊和聚餐，彼此鼓勵，在主愛中共同成長"
              }
            ]
          },
          {
            _id: 2,
            name: "Leisure Gospel Café :悠閑福音茶座 ",
            url: "/images/enoch_group.jpg",
            venue: "SCCC Church Center",
            time: "周二早上十二時 Tuesday morning 12am (僅於學期內舉行 School term time only)",
            contacts: [
              {
                name: "Irene Wai - 衞師母",
                email: "irene.wai@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "備有簡餐及茶水供應 Tea & Simply lunch supplied."
              }
            ]
          },
          {
            _id: 3,
            name: "男仕團契 Brother Fellowship (粵語)",
            venue: "SCCC Church Center",
            time: "第一、三 週四晚6-10, First & Third Thursday 6-10PM",
            url: "/images/we-are-one-body-in-Christ.jpg",
            contacts: [
              {
                name: "衞傳道 Pastor Henry",
                email: "henry.wai@sccc.org.uk"
              }
            ],
            description: [
              {
                text: ""
              }
            ]
          },
          {
            _id: 4,
            name: "Mandarin Women Fellowship : 国语妇女团契",
            url: "/images/enoch_group.jpg",
            venue: "SCCC Church Centre",
            time: "周五早上10点－12点 (学期内进行)",
            contacts: [
              {
                name: "Wendy Hong - 洪文清姊妹",
                email: "wenqing.hong@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "欢迎说国语的姐妹们加入我们团契，聚会内容有破冰游戏，唱诗歌，查经分享学习神的话语，祷告，我们还举办郊游和聚餐，彼此鼓励，在主爱中共同成长"
              }
            ]
          },
          {
            _id: 5,
            name: "Chinese Christian Fellowship : 學生團契 (粵語及英語 Cantonese & English)",
            url: "/images/mcf.jpg",
            venue: "Education Building 34, Room 4007 (at the rear of Hartley Library)",
            time: "Every Thursday at 7:30-9:30pm",
            contacts: [
              {
                name: "Tat Shing Yam",
                email: "tatshing.yam@sccc.org.uk"
              }
            ],
            description: [
              { text: "We are Southampton University Chinese Christian Fellowship (SUCCF) 南安大學華人基督徒團契. We meet every Friday night for worship, prayer, sharing and studying the Bible. We also regularly have theme talks, outings, café, hymn sharing, Bible quiz and fellowship meals." },
              { text: "Our meetings are in English and Cantonese 英語和粵話." },
              { text: "Our aim is to provide a spiritual home for students, to encourage and support one another through fellowship, and also share the good news of Jesus Christ to non-believers." },
              { text: "Our weekly meeting is normally in university. But once every month we hold a café in our Church Centre." },
              { text: "We warmly welcome all undergraduate, postgraduate students and working adults, no matter you are Christians or not." },
              { text: "We particularly welcome those who are interested in Christianity - those who would like to know more about who Jesus is and how he is relevant to us." },
              { text: "SUCCF Facebook - https://www.facebook.com/groups/succf" }
            ]
          },
           {
             _id: 6,
             name: "Mandarin Christian Fellowship : 國語基督團契",
             url: "/images/mcf.jpg",
             venue: "Education Building 34, Room 2003 (at the rear of Hartley Library)",
             time: "Every Friday at 8:00pm",
             contacts: [
               {
                 name: "Linda Hong",
                 email: "mcf@sccc.org.uk"
               }
             ],
             description: [
               {
                 text: "We welcome you to the Mandarin Christian Fellowship. Our aim is to encourage one another to grow together in Christ, through prayers, worship and studying His Word."
               },
               {
                 text: "We use the Mandarin language to share the love of Jesus, so we warmly welcome you wherever you are from. We currently have students and working adults from China, Malaysia, Singapore and Taiwan."
               },
               {
                 text: "欢迎加入国语基督团契(MCF). 藉着唱诗, 崇拜和祈祷一起来分享神的话语, 学习圣经和彼此鼓励, 共同在耶稣基督 里成长."
               },
               {
                 text: "MCF 的独特之处是以普通话来分享耶稣的爱, 所以无论你来自哪里热忱欢迎您的加入. 我们目前有从中国大陆, 马来西亚, 新加坡, 台湾等的学生和 职业人士."
               }
             ]
           },
           {
            _id: 7,
            name: "Family Fellowship : 家庭團契 (粵語及英語 Cantonese & English)",
            venue: "SCCC Church Center",
            time: "Every 2nd and 4th Saturday 4:00pm, 每月第二、四週星期六下午四時",
            url: "/images/enoch_group.jpg",
            contacts: [
              {
                name: "Tat Shing Yam",
                email: "tatshing.yam@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "We normally meet up every 2nd and 4th Saturday of every month. The speaking medium is Cantonese & English."
              }
            ]
          },
           {
            _id: 8,
            name: "Agape Family Fellowship : 愛加倍家庭團契 (国语)",
            venue: "SCCC Church Center",
            time: "Every 3rd Saturday 4pm, 每月第三週星期六下午四時",
            url: "/images/we-are-one-body-in-Christ.jpg",
            contacts: [
              {
                name: "Erik Hu",
                email: "erik.hu@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "We normally meet up at the 3rd week of every month on Saturday and the main language used is Mandarin. We aim to support one another in our Christian walk and in building Christ-Centred families."
              },
              {
                text: "我们通常在每个月的第 3 周的星期六聚會，使用的主要语言是普通话。 我们的目标是在我们的基督徒生活和建立以基督为中心的家庭中彼此扶持彼此建造。"
              }
            ]
          },
           {
            _id: 9,
            name: "Young Family Fellowship :年輕家庭團契 (粵語及英語 Cantonese & English)",
            venue: "SCCC Church Center",
            time: "Every 2 weeks on Saturday 1:00pm, 每隔兩週星期六下午一時",
            url: "/images/enoch_group.jpg",
            contacts: [
              {
                name: "Ho Lee Wong",
                email: "holee.wong@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "We normally meet up every 2 weeks on Saturday at 1:00pm. The speaking medium is Cantonese & English."
              }
            ]
          },
          {
            _id: 10,
            name: "職青團契 Young Adult Fellowship (YAF) (粵語)",
            time: "每月第四週星期六下午四時 Every 4th Saturday afternoon 4pm",
            venue: "家庭聚會 Home Meeting_",
            url: "/images/we-are-one-body-in-Christ.jpg",
            contacts: [
              {
                name: "梁弟兄 JasonLeung",
                email: "jason.leung@sccc.org.uk"
              }
            ],
            description: [
              {
                text: ""
              }
            ]
          }
    ]

    return meetings.map((meeting, index) => (
      <Meeting key={index} meeting={meeting} />
    ));
  }

  render() {

    return (
      <div>
        <div style={{width: '90%', margin: 'auto', textAlign:'left'}}>
          <Grid>
            {this.renderMeetings()}
          </Grid>
        </div>
      </div>

    );
  }
}
