import React, { Component } from 'react';
import { Card, CardTitle, CardText } from 'react-mdl';

import FontAwesome from 'react-fa';

export default  class Downloads extends Component {
  render() {

    const titleStyle = {
      color: 'white',
      background: "url(/images/blue_circle.jpg) center / cover"
    };

    return (
      <div>
        <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
          <CardTitle style={titleStyle}>Preaching Schhedule</CardTitle>
          <CardText>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2021-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2021</a><br/> 
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2021-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2021</a><br/> 
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2021-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2021</a><br/> 
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2021-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2021</a><br/>
            <br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2020-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2020</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2020-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2020</a><br/> 
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2020-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2020</a><br/> 
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2020-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2020</a><br/>
            <br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2019-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2019</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2019-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2019</a><br/> 
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2019-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2019</a><br/> 
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2019-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2019</a><br/>
            <br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2018-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2018</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2018-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2018</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2018-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2018</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2018-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2018</a><br/>
            <br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2017-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2017</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2017-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2017</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2017-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2017</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2017-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2017</a><br/>
            <br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2016-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2016</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2016-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2016</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2016-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2016</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2016-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2016</a><br/>
            <br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2015-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2015</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2015-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2015</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2015-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2015</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2015-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2015</a><br/>
            <br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2014-Q4.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : October ~ December 2014</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2014-Q3.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : July ~ September 2014</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2014-Q2.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : April ~ June 2014</a><br/>
            <a href="http://res.sccc.org.uk/docs/PreachingSchedule2014-Q1.pdf"><FontAwesome name='th-list'/>&nbsp;&nbsp;&nbsp;Sermon schedule : January ~ March 2014</a><br/>

          </CardText>
        </Card>
      </div>
    );
  }
}
