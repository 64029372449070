import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle, CardText } from 'react-mdl';

export default class Giving extends Component {
  render() {
    let leaflet = 'http://res.sccc.org.uk/docs/sccc-retreat-2024-poster.png';
    let InLineLeaflet =  <a href={leaflet}><img src={leaflet} alt="leaflet" style={{margin:"auto", padding:"10px", width:"75%"}}/></a>
    
    return (
      <div>
        <div style={{height: '225px', background: 'url(/images/we-are-one-body-in-Christ.jpg) center / cover'}}/>
        <div style={{width: '90%', margin: 'auto'}}>

          <Grid>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{background: 'url(/images/blue_circle.jpg) center / cover'}}>SCCC Summer Camp' 24</CardTitle>
                <CardText>
                  <h4 style={{textAlign: 'left'}}>
                  <li>2024 夏令會主題： 愛主更深</li>
                   <li>Speakers: 香港建道神學院 蔡少琪院長</li>
                   <li>日 期: 29 - 31 July 2024</li>
                   <li>地 點: King's Park Conference Centre, ings Park Road, Northampton, NN3 6LL</li>
                  <a href="https://forms.gle/Gq1YfNperKKejVTW9">Click Here or Scan QR Code to Register Now !</a>
                  <br/> <br/>
                  <a href="http://res.sccc.org.uk/docs/sccc-retreat-2024-poster.png">下載海報 !</a>
                  <br/>{InLineLeaflet}
                  <br/> <br/>
                </h4>
                </CardText>
                
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}
