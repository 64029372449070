import React, { Component } from 'react';
import {  Card, CardTitle, CardText, CardActions } from 'react-mdl';
import FontAwesome from 'react-fa';

export default class Meeting extends Component {

  render() {
    const titleStyle = {
      color: "white",
      background: this.props.titleBackground
    };

    let Speaker = this.props.event.speaker ? <div>&emsp;<FontAwesome name='microphone'/> :&emsp; {this.props.event.speaker}</div> : null;
    let Datetime = this.props.event.datetime ? <div>&emsp;<FontAwesome name='calendar'/> :&emsp; {this.props.event.datetime}</div> : null;
    let Venue = this.props.event.venue ? <div>&emsp;<FontAwesome name='map-marker'/> : &emsp; {this.props.event.venue}</div> : null;
    let BibleVerse = this.props.event.bible_verse ? <div>&emsp;<FontAwesome name='bookmark-o'/> : &emsp;{this.props.event.bible_verse}</div> : null;
    let Duration = this.props.event.duration ? <div>&emsp;<FontAwesome name='clock-o'/> :&emsp; {this.props.event.duration}</div> : null;
    let Description = this.props.event.description ? <div>&emsp;<FontAwesome name='info-circle'/> :&emsp; {this.props.event.description}</div> : null;

    let Media = this.props.event.recording.includes("https://youtu") ?
    <CardActions border>&emsp;<a href={this.props.event.recording}> <FontAwesome name='youtube-play'/></a> : &emsp;&emsp;<a href={this.props.event.recording}> SCCC YouTube Channel <FontAwesome name='video-camera'/></a></CardActions> :
    ( this.props.event.recording ? <CardActions border>&emsp;<a href={this.props.event.recording}> <FontAwesome name='download'/></a> : &emsp;&emsp;<audio src={this.props.event.recording} preload="metadata" controls></audio></CardActions> : null);

    let Leaflet = this.props.event.leaflet ?   <CardActions border>&emsp;<FontAwesome name='file-text-o'/> :&emsp; Invite your friends : <a href={this.props.event.leaflet}><img src={this.props.event.leaflet} alt="leaflet" style={{height:"32px", width:"32px"}}/></a></CardActions> : null;
    let InLineLeaflet = this.props.event.leaflet && this.props.event.leaflet.includes(".png")  ?   <a href={this.props.event.leaflet}><img src={this.props.event.leaflet} alt="leaflet" style={{margin:"auto", padding:"10px", width:"100%"}}/></a>: null;

    return (
        <Card shadow={0} style={{width: '100%', margin: 'auto'}} key={this.props.event._id}>
          <CardTitle style={titleStyle}>{this.props.event.name}</CardTitle>
          <CardText>
            {Speaker}
            {Datetime}
            {Venue}
            {BibleVerse}
            {Duration}
            {Description}
            {InLineLeaflet}
          </CardText>
            {Leaflet}
            {Media}
        </Card>
    );
  }
}
