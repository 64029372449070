import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle, CardText } from 'react-mdl';

export default class Giving extends Component {
  render() {
    return (
      <div>
        <div style={{height: '225px', background: 'url(/images/Praise-the-Lord-Christian.jpg) center / cover'}}/>
        <div style={{width: '90%', margin: 'auto'}}>

          <Grid>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{background: 'url(/images/yellow_background.jpg) center / cover'}}>Giving</CardTitle>
                <CardText>
                  <h4>How to give</h4>
                  <h6 style={{textAlign: 'left'}}>
                  As part of our worship let’s remember how God has blessed us and think about how we can use our resources for His work.
                  Let’s pray and listen carefully to what the Lord is saying to us as we give. 
<br/>
<br/>

Thank you for continuing to give to God's work through Southampton Chinese Christian Church during these unusual and seemingly difficult times.
                  </h6> 
                  <h4>Ways to give</h4>
                  <h6 style={{textAlign: 'left'}}>
                  You can use the following SCCC account details for one-off offering or set up regular offering with a standing order.</h6>
                  <h6 style={{textAlign: 'left'}}>
                   <li>Account name:  Southampton Chinese Christian Church</li>
                   <li>Sort code: 40-42-18</li>
                   <li>Account number: 52108968</li>
                   <br/>
To Gift Aid with online banking, please download a <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/468016/Charity_GAD_Multiple_Donations.pdf">Gift Aid form</a> and return it to <a href="mailto:treasurer@sccc.org.uk">treasurer@sccc.org.uk</a>.
                  </h6> 
                </CardText>
                
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}
