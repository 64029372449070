import React, { Component } from 'react';
import { Button, Cell, Card, CardTitle, CardText, CardActions } from 'react-mdl';
import FontAwesome from 'react-fa';

export default class Meeting extends Component {

  render() {

    const titleStyle = {
      height: '150px',
      background: 'url('+ this.props.meeting.url +') center / cover',
      color: 'white'
    };

    let leaflet = this.props.meeting.leaflet ?
    <CardActions border>
      <Button colored><a href={this.props.meeting.leaflet}> Leaflet (单张) : </a><a href={this.props.meeting.leaflet}><img src={this.props.meeting.leaflet} alt="leaflet" style={{height:"48px"}}/></a></Button>
    </CardActions>
    : null;

    return (
      <Cell col={12} key={this.props.meeting._id}>
        <Card shadow={0} style={{width: '100%', margin: 'auto'}} key={this.props.meeting._id}>
          <CardTitle style={titleStyle}>{this.props.meeting.name}</CardTitle>
          <CardText>
            &emsp;<FontAwesome name='calendar'/> : &emsp;{this.props.meeting.time} <br/>
            &emsp;<FontAwesome name='map-marker'/> : &emsp;{this.props.meeting.venue}
            {this.props.meeting.contacts.map((person, index)=>
              <p key={index}>
                &emsp;<FontAwesome name='address-card'/> : &emsp;{person.name}  <br/>
                &emsp;<FontAwesome name='envelope'/> : &emsp;{person.email} <br/>
              </p>
            )}
          </CardText>
          <CardActions border>
            {this.props.meeting.description.map((info, index) =>
              <p key={index}>{info.text}</p>
            )}
          </CardActions>
          {leaflet}
        </Card>
      </Cell>
    );
  }
}
