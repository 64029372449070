import React, { Component } from 'react';
import {  Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import FontAwesome from 'react-fa';
import Meeting from './Meeting.js';

export default class SundayServcies extends Component {

  renderMeetings() {
    const meetings = [
      {
             _id: 0,
             name: "Sunday School : 主日學",
             url: "images/sunday_school.jpg",
             venue: "Portswood Church",
             time: " Every Sunday, during both morning and afternoon Sunday services",
             contacts: [
               {
                 name: "Debbie Harris",
                 tel: "",
                 email: "debbie.harris@sccc.org.uk"
               }
             ],
             description: [
               {
                 text: "Children are highly valued at SCCC and we want every child to feel welcomed and appreciated in our children’s ministry. On Sunday afternoon the children join with the adults for a time of worship before going to their own groups. There are three age related groups for children and one for young people. We sometimes all meet together to have an action packed afternoon of activities."
               },
               {
                 text:"Our teaching from the Bible is both fun and interactive. The children are taught through a variety of activities including stories, songs, craft, games, drama and more."
               },
               {
                 text:"Age groups: Little Sunshine: Pre-school, Shooting Stars: Year R - Year 2, Supernovas: Year 3 - Year 6, Salt and Light: Year 7+"
               }
             ],
             leaflet:""
           },
           {
            _id: 1,
            name: "Music Makers",
            url: "/images/joy.jpg",
            venue: "Portswood Church",
            time: "Every Sunday after Sunday service, about 45 minutes (~3.45-4.30pm)",
            contacts: [
              {
                name: "李安蘭姊妹 Anna",
                email: "anna.lee@sccc.org.uk"
              }
            ],
            description: [
              {
                text: "Aims: 1) Children will learn the basics of music theory. 2) Children will learn how to sing God-honoring music 3) Children will be exposed to different kinds of simple musical instruments 4) Children will have fun singing Christian songs."
              },
              {
                text: "Parents who would like their children to join Music Makers, please enroll them by completing a registration form and return it to Jacq or Catherine at the email address above."
              },
              {
                text: "Leaders: 李安蘭姊妹 Anna, 沈沛惠姊妹Kristy, 陳秀安姊妹 Anne"
              }
            ]
          },
    ]

    return meetings.map((meeting, index) => (
      <Meeting key={index} meeting={meeting} />
    ));
  }

  render() {

    return (
      <div>
        <div style={{width: '90%', margin: 'auto', textAlign:'left'}}>
          <Grid>
            {this.renderMeetings()}
          </Grid>
        </div>
      </div>

    );
  }
}
