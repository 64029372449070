import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import { Tabs,Tab } from 'react-mdl';

export default class Resources extends Component {

  tabSelected(tabId) {
    switch (tabId) {
      case 0: hashHistory.push('/resources/sermons'); break;
      case 1: hashHistory.push('/resources/downloads'); break;
      case 2: hashHistory.push('/resources/media'); break;
      default: break;
    }
  }

  render() {
    return (
      <div>
        <div style={{height: '225px', background: 'url(/images/bible_study.jpg) center / cover'}}/>
        <div style={{width: '90%', margin: 'auto', textAlign:'left'}}>
          <Tabs ripple activeTab={0} onChange={this.tabSelected} style={{height:'100px'}}>
            <Tab>Sermons</Tab>
            <Tab>Downloads</Tab>
            <Tab>Media</Tab>
          </Tabs>
          <div className="page-content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
