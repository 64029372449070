import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Router, Route, IndexRoute, hashHistory } from 'react-router'

import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import Home from './home/Home';
import Meetings from './meetings/Meetings';
import Events from './events/Events';
import UpcomingEvents from './events/UpcomingEvents';
import PastEvents from './events/PastEvents';
import Sermons from './resources/Sermons';
import Resources from './resources/Resources';
import Downloads from './resources/Downloads';
import Media from './resources/Media';
import AboutUs from './about/AboutUs';
import Giving from './giving/Giving';
import Camp from './camp/SummerCamp';
import SundayServices from './meetings/SundayServices';
import Fellowships from './meetings/Fellowships';
import PrayerMeetings from './meetings/PrayerMeetings';
import ChildrenSundaySchool from './meetings/ChildrenSundaySchool';
import AdultSundaySchool from './meetings/AdultSundaySchool';
import FamilyGatherings from './meetings/FamilyGatherings';


ReactDOM.render(
  <Router history={hashHistory}>
    <Route path="/" component={App}>
    <IndexRoute component={Home}/>
    <Route path="home" component={Home}/>

    <Route path="meetings" component={Meetings}>
      <IndexRoute component={SundayServices}/>
      <Route path="services" component={SundayServices}/>
      <Route path="fellowships" component={Fellowships}/>
      <Route path="prayers" component={PrayerMeetings}/>
      <Route path="childrensundayschool" component={ChildrenSundaySchool}/>
      <Route path="adultsundayschool" component={AdultSundaySchool}/>
      <Route path="familygatherings" component={FamilyGatherings}/>
    </Route>

    <Route path="events" component={Events}>
      <IndexRoute component={UpcomingEvents}/>
      <Route path="upcoming" component={UpcomingEvents}/>
      <Route path="past" component={PastEvents}/>
    </Route>

    <Route path="resources" component={Resources}>
      <IndexRoute component={Sermons}/>
      <Route path="sermons" component={Sermons}/>
      <Route path="downloads" component={Downloads}/>
      <Route path="media" component={Media}/>
    </Route>

    <Route path="camp" component={Camp}/>

    <Route path="giving" component={Giving}/>

    <Route path="aboutus" component={AboutUs}/>

    <Route path="*" component={App}/>

  </Route>
</Router>,
document.getElementById('root')
);
