import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import FontAwesome from 'react-fa';

export default class FamilyGatherings extends Component {
  render() {

    return (
      <div>
        <div style={{width: '90%', margin: 'auto'}}>

          <Grid>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{ color: '#fff', height: '150px',  background: 'url(/images/lighthouse.jpg) center / cover'}}>家庭聚會</CardTitle>
                <CardText>
                  <p>
                    &emsp;<FontAwesome name='calendar'/> : &emsp;2023年2月份開始 <br/>
                    &emsp;<FontAwesome name='map-marker'/> : &emsp;各參加家庭輪流負責主辦<br/>
                    &emsp;<FontAwesome name='address-card'/> : &emsp;衞傳道 Pastor Henry <br/>
                    &emsp;<FontAwesome name='envelope'/> : &emsp;henry.wai@sccc.org.uk <br/>
                  </p>
<h5>聚會簡介：</h5>
<h6>家庭聚會預算在二月份開始，教會將安排組合家庭輪流在各區中舉行聚會，內容主要有敬拜，神話語分享和禱告，目的是加強弟兄姊妹間的關懷，建立愛心服事，屬靈生命建造，聯繫教會。
</h6>
<h5>參加者：</h5>
<h6>凡已在本教會有恆常聚會的弟兄姊妹，願意委身於教會，渴慕過屬靈和肢體的生活都可以參加。</h6>
<h5>聚會形式：</h5>
<h6>聚會將會在弟兄姊妹家中，以敬拜、話語和禱告為主要內容，在週間任何合適時間都可以聚會，最後由參加家庭決定時間，教會提供讀經材料及協助，鼓勵家庭輪流担任招待和主領聚會，現階段是建立現有弟兄姊妹關係過肢體生活， 不鼓勵新來賓參加聚會，
新來賓可邀請到各團契中認識和聚會。</h6>
<h5>參加辦法：</h5>
<h6>
凡願意參加者均需報名，教會按地區及人數安排分組，各組再按各家庭需要固定會聚會時間。請填寫以下參加表格即可。</h6>
<br/><br/>
                </CardText>
                <CardActions border>
                  <Button colored><a href="https://forms.gle/aQXSgB1YTzX57VmD7">Online Registration Form</a></Button>
                </CardActions>
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}
