import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import FontAwesome from 'react-fa';

export default class AdultSundaySchool extends Component {
  render() {

    return (
      <div>
        <div style={{width: '90%', margin: 'auto'}}>

          <Grid>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{ color: '#fff', height: '150px',  background: 'url(/images/lighthouse.jpg) center / cover'}}>成人主日學 Adult Sunday School (粵語/国语)</CardTitle>
                <CardText>
                  <p>
                    &emsp;<FontAwesome name='calendar'/> : &emsp;逢第二、三、四主日，晚上八時至九時 <br/>
                    &emsp;<FontAwesome name='map-marker'/> : &emsp;Zoom  或疫後 Portswood Church <br/>
                    &emsp;<FontAwesome name='address-card'/> : &emsp;衞傳道 Pastor Henry <br/>
                    &emsp;<FontAwesome name='envelope'/> : &emsp;henry.wai@sccc.org.uk <br/>
                  </p>
                  <h6>
                  教會將於2024年七月份開始新一季的成人主日學課程，詳細的內容如下:<br/><br/>

                  廣東話：挑戰視野 - 對普世宣教的認識<br/><br/>

    
地點：Zoom  <br/>
課程簡介：<br/>
&emsp;1)廣東話：挑戰視野 - 對普世宣教的認識<br/>
&emsp;&emsp;時間：)2024年7/7; 21/7; 28/7; 4/8; 11/8; 18/8 主日，晚上八時至九時，共六課。
。<br/>
&emsp;&emsp;課程簡介：課程：廣東話---《挑戰視野》改自英文“N Vision”，後者亦以Perspectives on the World Christian Movement(中譯：《普世宣教運動面面觀》，台灣譯作《宣教心視野》)為藍本。本課程屬同類中最濃縮的精華版本，讓信徒可以明白普世宣教的聖經基礎、歷史進程、跨越文化、生命策略等4大方面的內容。盼望提高教會和會眾的差傳意識。挑戰視野通用版由香港差傳事工聯會編撰，出版及發行。版權屬香港差傳事工聯會有限公司所擁有。<br/><br/>
&emsp;&emsp;&emsp;第一課普世宣教的聖經基礎<br/>
&emsp;&emsp;&emsp;第二課宣教歷史(上)<br/>
&emsp;&emsp;&emsp;第三課宣教歷史(下)<br/>
&emsp;&emsp;&emsp;第四課認識文化與宣教的關係<br/>
&emsp;&emsp;&emsp;第五課心懷普世、認識需要<br/>
&emsp;&emsp;&emsp;第六課如何參與宣教及生命的裝備<br/>
<br/>
                    </h6>
                </CardText>
                <CardActions border>
                  <Button colored><a href="https://forms.gle/MkuoK3ZmnjCEYETA7">Online Registration Form</a></Button>
                </CardActions>
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}
