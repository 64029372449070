import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import { Tabs, Tab } from 'react-mdl';

export default class Events extends Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: 2 };
  }

  tabSelected(tabId) {
    switch (tabId) {
      case 0: hashHistory.push('/events/upcoming'); break;
      case 1: hashHistory.push('/events/past'); break;
      default: break;
    }
  }

  render() {
    return (
      <div>
        <div style={{height: '225px', background: 'url(/images/heart_2cor_146.jpg) center / cover'}}/>
        <div style={{width: '90%', margin: 'auto', textAlign:'left'}}>
          <Tabs ripple activeTab={0} onChange={this.tabSelected} style={{height:'100px'}}>
            <Tab>Upcoming Events</Tab>
            <Tab>Past Events</Tab>
          </Tabs>
        <div className="page-content">
          {this.props.children}
        </div>
      </div>
      </div>
    );
  }
}
