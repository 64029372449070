import React, { Component } from 'react';
import {  Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import FontAwesome from 'react-fa';

export default class SundayServcies extends Component {

  render() {

    return (
      <div>
        <div style={{width: '90%', margin: 'auto', textAlign:'left'}}>
          <Grid >
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{ color: '#fff', height: '150px',  background: 'url(/images/praise_You.jpg) center / cover'}}>Sunday Service : 主日崇拜</CardTitle>
                <CardText>
                  <p>
                    &emsp;<FontAwesome name='calendar'/> : &emsp;Every Sunday (2:00pm) <br/>
                    &emsp;<FontAwesome name='map-marker'/> : &emsp;Portswood Church
                  </p>
                  <h6>Our services are conducted in Mandarin and Cantonese. English translation will be arranged as required.</h6>
                </CardText>
                <CardActions border>
                  <Button colored><a href="http://res.sccc.org.uk/docs/Church_Group_meetings.pdf">Leaflet of Our Regular Meetings (教会的聚会单张)</a></Button>
                </CardActions>

              </Card>
            </Cell>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{ color: '#fff', height: '150px',  background: 'url(/images/we-are-one-body-in-Christ.jpg) center / cover'}}>Cantonese Sunday Service : 粵語主日崇拜</CardTitle>
                <CardText>
                  <p>
                    &emsp;<FontAwesome name='calendar'/> : &emsp;Every Sunday (10:00am) <br/>
                    &emsp;<FontAwesome name='map-marker'/> : Richard Taunton Sixth Form College
                  </p>
                  <h6>This services is conducted in Cantonese. The booking is no longer required for Sunday school children.</h6>
                </CardText>
                <CardActions border>
                  <Button colored><a href="http://res.sccc.org.uk/docs/Church_Group_meetings.pdf">Leaflet of Our Regular Meetings (教会的聚会单张)</a></Button>
                </CardActions>
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>

    );
  }
}
