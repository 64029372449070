import React, { Component } from 'react';
import './App.css';
import { Link } from 'react-router'
import { Layout, Drawer, Header, HeaderRow, Navigation, Content } from 'react-mdl';


export default class App extends Component {

  render() {

    const NavMenu = <Navigation>
      <Link to="/home" activeClassName="active" style={{fontWeight:"bold"}}>Home</Link>
      <Link to="/meetings" activeClassName="active" style={{fontWeight:"bold"}}>Regular Meetings</Link>
      <Link to="/events" activeClassName="active" style={{fontWeight:"bold"}}>Special Events</Link>
      <Link to="/resources" activeClassName="active" style={{fontWeight:"bold"}}>Online Resources</Link>
      <Link to="/camp" activeClassName="active" style={{fontWeight:"bold"}}>Summer Camp</Link>
      <Link to="/giving" activeClassName="active" style={{fontWeight:"bold"}}>Giving</Link>
      <Link to="/aboutus" activeClassName="active" style={{fontWeight:"bold"}}>About Us</Link>
    </Navigation>

    return (
      <div className="App">
        <Layout>
          <Header title="Southampton Chinese Christian Church : 南安普敦華人基督教會" className="mdl-layout--large-screen-only"
            style={{fontWeight: 'bold', background: 'rgba(0, 102, 255, 0.55)'}}>
            {NavMenu}
          </Header>

          <HeaderRow title="SCCC" className="mdl-layout--small-screen-only"
            style={{fontWeight: 'bold', background: 'rgba(0, 102, 255, 0.55)'}}/>
            <Drawer title="SCCC" className="mdl-layout--small-screen-only">
              {NavMenu}
            </Drawer>

            <Content >
              <div>
                {this.props.children}
              </div>
            </Content>
          </Layout>
        </div>
      );
    }
  }
