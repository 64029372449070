import React, { Component } from 'react';
import {  Grid, Cell } from 'react-mdl';
import Event from './Event.js'

export default class PastEvents extends Component {
  state = {
    events: []
  }

  componentDidMount() {
    fetch('https://sccc.org.uk/server/api/index.php/events/past/5')
    .then(res => res.json())
    .then(data => data.events.slice(0,20) )
    .then(events => this.setState({ events:events }));
  }

  renderEvents() {
    return this.state.events.map((event, index) => (
      <Cell col={12} key={index}>
        <Event key={index} event={event} titleBackground="url(/images/grey_background.jpg) center / cover"/>
      </Cell>
    ));
  }

  render() {
    return (
      <div>
        <h3>Past Events</h3>
        <Grid>
          {this.renderEvents()}
        </Grid>
      </div>
    );
  }


}
