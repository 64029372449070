import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import { Tabs,Tab } from 'react-mdl';

export default class Resources extends Component {

  tabSelected(tabId) {
    switch (tabId) {
      case 0: hashHistory.push('/meetings/services'); break;
      case 1: hashHistory.push('/meetings/fellowships'); break;
      case 2: hashHistory.push('/meetings/prayers'); break;
      case 3: hashHistory.push('/meetings/childrensundayschool'); break;
      case 4: hashHistory.push('/meetings/adultsundayschool'); break;
      case 5: hashHistory.push('/meetings/familygatherings'); break;
      default: break;
    }
  }

  render() {
    return (
      <div>
        <div style={{height: '225px', background: 'url(/images/praise_worship.jpg) center / cover'}}/>
        <div style={{width: '90%', margin: 'auto', textAlign:'left'}}>
          <Tabs ripple activeTab={0} onChange={this.tabSelected} style={{height:'100px'}}>
            <Tab>Sunday Services</Tab>
            <Tab>Fellowships</Tab>
            <Tab>Prayer Meetings</Tab>
            <Tab>Children Sunday School</Tab>
            <Tab>Adult Sunday School</Tab>
            <Tab>Family Gatherings</Tab>
          </Tabs>
          <div className="page-content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
