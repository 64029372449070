import React, { Component } from 'react';
import { Link } from 'react-router'
import {  Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';

import Slider from 'react-slick';
import FontAwesome from 'react-fa';

export default class Home extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000
    };

    const slideshowImages = [
      {
        url: 'images/shine-light-sun.jpg',
      },
      {
        url: 'images/praise_the_Lord.jpg',
      },      {
        url: 'images/heart_2cor_146.jpg',
      },      {
        url: 'images/bible_study.jpg',
      },      {
        url: 'images/praise_You.jpg',
      },
    ];
    
    return (
      <div>
        <Slider {...settings}>
          {slideshowImages.map((image, index) => (
            <div key={index} style={{width: '100%', height: '400px'}}>
              <div
                style={{
                  backgroundImage: `url(${image.url})`,
                  width: '100%',
                  height: '300px',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }} />
            </div>
          ))}
        </Slider>
        <div style={{width: '90%', margin: 'auto'}}>

          <Grid >
            <Cell col={12}>
              <h4> <font color="blue">Welcome to SCCC website </font></h4>
              <h6>
                We hope this website will help you find more information bout our church, members and what we believe in. You can easily access the relevant information by clicking at the menu bar or tabs on the top.
              </h6>

              <h6>
                There is no better way to get to know us other than joining us in one of the <Link to="events">upcoming events</Link> or even come to one of our various <Link to="meetings">regular meetings</Link>!
              </h6> 
              <h6>
                We warmly wish you a pleasant and enriching stay here at Southampton :)
              </h6>
            </Cell>
          </Grid>

          <Grid>
            <Cell col={4}>
              <Link to="meetings">
                <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                  <CardTitle style={{color: '#fff', height: '200px', background: 'url(images/we-are-one-body-in-Christ.jpg) center / cover'}}>Regular Meetings</CardTitle>
                  <CardActions border>
                    <Button colored>Find out more</Button>
                  </CardActions>
                </Card>
              </Link>
            </Cell>
            <Cell col={4}>
              <Link to="events">
                <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                  <CardTitle expand style={{color: '#fff', height: '200px', background: 'url(images/heart_2cor_146.jpg) center 50% '}}>Special Events</CardTitle>
                  <CardActions border>
                    <Button colored>Find out More</Button>
                  </CardActions>
                </Card>
              </Link>
            </Cell>
            <Cell col={4}>
              <Link to="resources">
                <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                  <CardTitle style={{color: '#fff', height: '200px', background: 'url(images/online_resources.jpg) center / cover'}}>Online Resources</CardTitle>
                  <CardActions border>
                    <Button colored>Find out More</Button>
                  </CardActions>
                </Card>
              </Link>
            </Cell>
          </Grid>
          <Grid>
            <Cell col={12}>
              <h4>Get In Touch</h4>
              <h6>
                If you would like more information about the church or the Christian faith, please email <a href="mailto:contact@sccc.org.uk">contact@sccc.org.uk</a>.
              </h6>
              <h6>
                You may also visit <a href="https://en-gb.facebook.com/sotonccc/">our SCCC's facebook</a> to find out our previous events.
              </h6>
              <h4>...</h4>
              <h4>How to Find Us</h4>
              <h6>By God's grace, we now have our own church centre, which we use as a base for various community outreach and fellowship meetings.</h6>
              <h6>We meet up at Portswood Church for <b>our afternoon Sunday services</b> and meet up at Richard Taunton Sixth Form College for <b>our morning Cantonese servcies</b>.</h6>
                  <h6></h6>
              <Grid>
                <Cell col={4}>
                    <Card shadow={0} style={{width: '85%', margin: 'auto'}}>
                      <CardTitle style={{color: '#fff', height: '100px', background: 'url(images/blue_background.jpg) center / cover'}}>SCCC Church Center</CardTitle>
                      <CardText>
                      <strong>Ancasta Rd, Southampton, SO14 6SL (near Aldi)</strong>
                      </CardText>
                      <CardActions border>
                        <a href="https://www.google.nl/maps/place/Southampton+Chinese+Christian+Church+Centre/@50.917458,-1.3968363,18.32z/data=!4m12!1m6!3m5!1s0x0:0xf2f6db8345f88d37!2sSouthampton+Chinese+Christian+Church!8m2!3d50.923759!4d-1.394119!3m4!1s0x0:0xcc4d9101b482ab0b!8m2!3d50.9174903!4d-1.3965015"><Button colored>Find SCCC Church Center on Google Maps</Button></a>
                      </CardActions>
                    </Card>
                </Cell>
                <Cell col={4}>
                    <Card shadow={0} style={{width: '85%', margin: 'auto'}}>
                      <CardTitle style={{color: '#fff', height: '100px', background: 'url(images/yellow_background.jpg) center / cover'}}>Portswood Church Building</CardTitle>
                      <CardText>
                      <strong>Portswood Road, Southampton, SO17 2FY (opposite Waitrose)</strong>
                      </CardText>
                      <CardActions border>
                        <a href="https://www.google.nl/maps/place/Southampton+Chinese+Christian+Church/@50.923759,-1.394119,15z/data=!4m5!3m4!1s0x0:0xf2f6db8345f88d37!8m2!3d50.923759!4d-1.394119"><Button colored>Find Portswood Church on Google Maps</Button></a>
                      </CardActions>
                    </Card>
                </Cell>
                <Cell col={4}>
                    <Card shadow={0} style={{width: '85%', margin: 'auto'}}>
                      <CardTitle style={{color: '#fff', height: '100px', background: 'url(images/blue_background.jpg) center / cover'}}>Richard Taunton Sixth Form College</CardTitle>
                      <CardText>
                      <strong>Hill Ln, Southampton, SO15 5RL</strong>
                      </CardText>
                      <CardActions border>
                        <a href="https://www.google.co.uk/maps/place/Richard+Taunton+Sixth+Form+College/@50.9267567,-1.4188392,17z/data=!4m5!3m4!1s0x48747419439d563b:0x44b4a003a7c82fe6!8m2!3d50.9267533!4d-1.4166505"><Button colored>Find Richard Taunton Sixth Form College on Google Maps</Button></a>
                      </CardActions>
                    </Card>
                </Cell>
              </Grid>

            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}
