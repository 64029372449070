import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle, CardText } from 'react-mdl';

export default class AboutUs extends Component {
  render() {
    return (
      <div>
        <div style={{height: '225px', background: 'url(/images/praise_You.jpg) center / cover'}}/>
        <div style={{width: '90%', margin: 'auto'}}>

          <Grid>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{background: 'url(/images/blue_circle.jpg) center / cover'}}>History</CardTitle>
                <CardText>
                  <h6>Southampton Chinese Christian Church first started over 10 years ago through the fellowship of a small number of local Chinese Christians.
                  They met to study God's word as given in the Bible, to praise, pray and worship the Lord Jesus Christ through regular fellowship and commitment.
                  As the group grew, it begun holding Sunday services at the Milbrook Christian Church.</h6>
                  <h6>After a number of years meeting in Milbrook with the ever increasing number of overseas Chinese students attending, the group moved to new premises and now meets at Portswood Church.
                  This has provided a more central location for both the local Chinese and also students and is within easy reach of the city centre.</h6>
                  <h6>The body of Christians who meet together include students (both undergraduate and postgraduate), families, and those in the local catering business and other professions.
                  The majority of those that attend are ethnically Chinese (both local and overseas from China, Hong Kong, Malaysia,Singapore,etc).</h6>
                  <h6>Sunday service is now conducted in English, Cantonese, and Mandarin. There are also Sunday School classes which are also held weekly during the main service, catering for a number of different age groups.
                  Outside of the main Sunday Service, there are four Bible study groups which meet regularly throughout the week.</h6>
                </CardText>
              </Card>
            </Cell>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{background: 'url(/images/blue_circle.jpg) center / cover'}}>異象 / Vision</CardTitle>
                <CardText>
                <h5>靠著神的恩典, 我們要成為 : By God’s grace, we will become:</h5>
                <h4><font color="blue">有生命的教會 - 對內 / A Living Church - Inwardly</font></h4>
                <h6 style={{textAlign: 'left'}}>以聖經為基礎；訓練門徒成長；培育對福音的熱誠；成為充滿愛心的教會並不斷成長。</h6>
                <h6 style={{textAlign: 'left'}}>Based on the Bible as our foundation, we hope to train disciples to grow, cultivate
                enthusiasm for the Gospel, and become a loving church and grow continuously.</h6>

                <h4><font color="blue">外展性的教會 - 對外 / An Outreaching Church – Outwardly</font></h4>
                <h6 style={{textAlign: 'left'}}>藉著對福音的熱誠，建做一個在英國南部的福音基地，向南安普敦以外地區擴展，為未得
                之民努力。</h6>
                <h6 style={{textAlign: 'left'}}>With our enthusiasm for the Gospel, we hope to build a Gospel base in southern
                England, to expand beyond Southampton, and to reach out to those who have not
                received the Good News.</h6>

                <h3>異象目標 / Vision Objectives</h3>
                <h4><font color="blue">異象：有生命的教會，外展性的教會 / Vision: A Living Church, An Outreaching Church</font></h4>
                <h5 style={{textAlign: 'left'}}>十年目標：成為有能力拓展堂會的教會 / Ten-Year Goal: To become a church capable of expanding and planting new churches.</h5>
                <h6 style={{textAlign: 'left'}}>
                <li>弟兄姊妹對福音充滿熱誠 / For brothers and sisters in Christ to grow in passion about the Gospel, </li>
                <li>讓未信主朋友容易溶入團契聚會並認識主 / Allowing non-believers to feel welcomed in fellowships and know more about
God,
</li><li> 讓團契成為未信朋友的橋樑，用愛心和關懷友善地來介紹福音 / Let fellowships be a bridge for non-believers and to introduce the Gospel to them
through love and care for one another,</li>
                <li>弟兄姊妹在團契中過肢體的生活，裝備自己，服事來賓 / For brothers and sisters to live as the Body of Christ through fellowships, and to
equip themselves to serve newcomers to the church,</li>
                <li>弟兄姊妹在教會中生命成長，服事教會及弟兄姊妹 / For brothers and sisters to mature in their spiritual lives, and to serve the church
and one another.</li>
                <li>教會聚會人數達250人 / For church service attendees to reach 250 people.</li>
                </h6>

                <h3>成長策略 / Strategies for Growth</h3>
                <h5 style={{textAlign: 'left'}}>藉團契作為訓練，肢體生活，靈命成長，福音預工來推動教會成長 / Using fellowships as settings for training, living as the Body of Christ, spiritual growth,
preparation for Gospel sharing and promoting church growth:</h5>
                <h6 style={{textAlign: 'left'}}>
                <li>訓練 - 培育新信徒在團契中學習，發挖恩賜，彼此服事 / Training – To enable new believers to learn in fellowships, discover their spiritual
gifts and serve one another,</li>
 <li>肢體生活 - 在愛中互相勉勵，聖經中互相學習，生活中互相分享 / Living as the Body of Christ – To encourage each other in love, learn together
from the Bible, and share with one another in daily lives,</li>
 <li>靈命成長 - 禱告，讀經，讚美 / Spiritual growth – To pray, study the Bible, and praise the Lord,</li>
 <li>福音預工 - 關懷未信朋友過團契生活，介紹福音，鼓勵信主 / Preparation for Gospel sharing – To care for non-believers and invite them to
fellowships, introduce the Gospel and encourage them to believe in Christ,</li>
<li>推動教會成長 - 積極參與教會佈道工作，探訪，關懷社區，學生工作等 / Promoting church growth – To actively participate in church’s evangelistic
ministries, visiting and community initiatives, and student ministries.</li>
                </h6>
                </CardText>
              </Card>
            </Cell>
             <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{background: 'url(/images/blue_circle.jpg) center / cover'}}>Doctrinal Basis</CardTitle>
                <CardText>
                  <h6>To view the doctrinal basis of our church and what we believe in, please refer to <a href="http://res.sccc.org.uk/docs/doctrinal_basis.pdf">Doctrinal Basis</a>.</h6>
                </CardText>
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}
