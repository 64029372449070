import React, { Component } from 'react';
import { Card, CardTitle, CardText } from 'react-mdl';

export default  class Media extends Component {

  render() {

    const titleStyle = {
      color: 'white',
      background: "url(/images/yellow_background.jpg) center / cover"
    };

    return (
      <div>
        <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
          <CardTitle style={titleStyle}>Videos</CardTitle>
          <CardText>
            <ul>
              <li><a href="http://res.sccc.org.uk/media/SCCC-Retreat-2015-agm.mp4"><h6>SCCC 2015 Retreat</h6></a></li>
              <li><a href="http://res.sccc.org.uk/media/SCCC-Retreat-2013.mov"><h6>SCCC 2013 Retreat</h6></a></li>
            </ul>
          </CardText>
        </Card>
      </div>
    );
  }
}
